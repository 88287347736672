(function(){
  'use strict';
  angular
    .module('app')
    .component('generalInvoice', {
      templateUrl: 'app/components/general-invoice/general-invoice.html',
      controller: EmployeeInvoicesController
    });

    EmployeeInvoicesController.$inject = ['user', 'globalService', '$uibModal', '_','$rootScope']

  function EmployeeInvoicesController($user, $globalService, $uibModal, _, $rootScope) {
    var vm = this;
    vm.loadingData = false;

    vm.currentUser = $user.getCurrentUser();

    vm.$onInit = function(){
      vm.params = {close: false}
      vm.date_from = moment().startOf('week');
      vm.date_to = moment().endOf('week');
      $rootScope.$emit('uploadBreadCrum', {current: '', before: 'Home'});
      $globalService.users.query().$promise
      .then(function(res) {
        vm.users = res;
      })
      var data = {
        close: vm.params.close,
        date_from: moment(vm.date_from).format('YYYY-MM-DD'),
        date_to: moment(vm.date_to).format('YYYY-MM-DD')
      }
      $globalService.generalInovices.query(data).$promise
      .then(function(res) {
        vm.invoices = _.map(res, function (data) {
          data.urlReport = $globalService.reportPdfWithAction('general_invoices',data.id,{token: vm.currentUser.token, id: data.id});
          return data;
        });;
        vm.total = _.reduce(res, function(memo, data) {
          memo.total += data.total_amount;
          memo.apps += data.applications.length;
        return memo;  
        },{total: 0, apps: 0})
      })

      $globalService.customPayments.query().$promise
      .then(function (res) {
        vm.customPayments = res;
      })
    }


    vm.generateInvoice = function() {
      var modal = $uibModal.open({
        animation: true,
        component: 'generalInvoiceModal',
        windowClass: 'show',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          users: function(){
            return vm.users;
          },
          customPayments: function () {
            return vm.customPayments;
          }
        }
      })

      modal.result.then(function(res){
        vm.$onInit();
      });
    }

    vm.editInvoice = function(data) {
      var modal = $uibModal.open({
        animation: true,
        component: 'generalInvoiceModal',
        windowClass: 'show',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          users: function(){
            return vm.users;
          },
          invoice: function () {
            return data;
          }
        }
      })

      modal.result.then(function(res){
        vm.$onInit();
      });
    }

    vm.destroyInvoince = function (params) {
      vm.loadingData = true;
      vm.code = _.random(9999);
      swal({
        title: 'Enter this code '+vm.code+' to confirm this action.',
        input: 'number',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function(code) {
          if(code == vm.code){
            return $globalService.generalInovices.delete({id: params.id}).$promise
            .then(function(res){
              vm.loadingData = false;
              vm.$onInit();
              Swal('Exito',res.message,'success');
            }, function(err){
              vm.loadingData = false;
              swal.showValidationError(err.data.message);
            });
          }
        }
      })
    }

    vm.consultInvoices = function() {
      vm.params.date_from = moment(vm.date_from).format('YYYY-MM-DD');
      vm.params.date_to = moment(vm.date_to).format('YYYY-MM-DD');
      $globalService.generalInovices.query(vm.params).$promise
      .then(function(res) {
        vm.invoices = _.map(res, function (data) {
          data.urlReport = $globalService.reportPdfWithAction('general_invoices',data.id,{token: vm.currentUser.token, id: data.id});
          return data;
        });;
        vm.total = _.reduce(res, function(memo, data) {
          memo.total += data.total_amount;
          memo.apps += data.applications.length;
        return memo;  
        },{total: 0, apps: 0})
      })
    }

    vm.sendInvoices = function() {
      vm.loadingData = true;
      vm.params.date_from = moment(vm.date_from).format('YYYY-MM-DD');
      vm.params.date_to = moment(vm.date_to).format('YYYY-MM-DD');
      swal({
        title: 'Are you sure',
        text: 'to sent all payroll receipt?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.sentGeneralInvoices(vm.params)
            .then(function(res) {
              vm.loadingData = false;
              resolve(res)
            }, function (err) {
              vm.loadingData = false;
              reject(err);
            })
          });
        },
        allowOutsideClick: false,
      }).then(function (res) {
        Swal('Success',res.message,'success');
      }, function (error){
        Swal('Error', error.data.message, 'warning');
      });
    }

    vm.sendInvoice = function(invoice) {
      vm.loadingData = true;
      vm.params.date_from = moment(vm.date_from).format('YYYY-MM-DD');
      vm.params.date_to = moment(vm.date_to).format('YYYY-MM-DD');
      vm.params.user_id = invoice.user_id;
      swal({
        title: 'Are you sure',
        text: 'to sent all payroll receipt?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.sentGeneralInvoices(vm.params)
            .then(function(res) {
              vm.loadingData = false;
              resolve(res)
            }, function (err) {
              vm.loadingData = false;
              reject(err);
            })
          });
        },
        allowOutsideClick: false,
      }).then(function (res) {
        Swal('Success',res.message,'success');
      }, function (error){
        Swal('Error', error.data.message, 'warning');
      });
    }
  }
})();
