angular.module("app").run(actionCable).component("webLayout", {
  templateUrl: "app/components/layouts/web-layout.html",
  controller: WebLayoutController,
});

actionCable.$inject = ["ActionCableConfig", "BASE_URL", "user"];

WebLayoutController.$inject = [
  "user",
  "$rootScope",
  "$window",
  "ActionCableChannel",
  "toastr",
  "ActionCableConfig",
  "BASE_URL",
  "$timeout",
];

function actionCable(ActionCableConfig, BASE_URL, $user) {
  var current_user = $user.getCurrentUser();
  if (current_user) {
    if (BASE_URL.split("/")[2].split(":")[0] == "localhost") {
      ActionCableConfig.wsUri =
        "ws://" +
        BASE_URL.split("/")[2] +
        "/cable?token=" +
        $user.getCurrentUser().token;
    } else {
      ActionCableConfig.wsUri =
        "wss://" +
        BASE_URL.split("/")[2] +
        "/cable?token=" +
        $user.getCurrentUser().token;
    }
  }
  ActionCableConfig.autoStart = true;
}

function WebLayoutController(
  $user,
  $rootScope,
  $window,
  ActionCableChannel,
  toastr,
  ActionCableConfig,
  BASE_URL,
  $timeout
) {
  var vm = this;
  vm.showBar = false;
  vm.user_back = $window.localStorage.getItem("user_back");
  vm.currentUser = $user.getCurrentUser();

  vm.$onInit = function () {
    if (BASE_URL.split("/")[2].split(":")[0] == "localhost") {
      ActionCableConfig.wsUri =
        "ws://" +
        BASE_URL.split("/")[2] +
        "/cable?token=" +
        $user.getCurrentUser().token;
    } else {
      ActionCableConfig.wsUri =
        "wss://" +
        BASE_URL.split("/")[2] +
        "/cable?token=" +
        $user.getCurrentUser().token;
    }

    
    var channel = new ActionCableChannel("SmsChannel", {
      token: vm.currentUser.token,
    });
    var callback = function (message) {
      toastr.success("Sms from " + message.phone_number, "New SMS");

      var panelConversations = document.getElementById("smsManagement");
      if (panelConversations) {
        var tr = document.getElementById(message.crmid);
        if (tr) {
          tr.classList.add("new-sms");
          $rootScope.$emit("newSms", message);
        }
      }
    };
    channel.subscribe(callback).then(function () {
      vm.sendToMyChannel = function (message) {
        channel.send(message);
      };

      var systemNotificationChannel = new ActionCableChannel(
        "SystemNotificationChannel",
        { token: vm.currentUser.token }
      );
      var callback = function (notification) {
        vm.notification = notification;
        if (vm.notification.hasOwnProperty("user_id")) {
          if (vm.currentUser.id == notification.user_id) {
            toastr.info(notification.message, "Client signature", {
              iconClass: "toast-custom no-translate",
              extendedTimeOut: 6000,
              autoDismiss: false,
              allowHtml: true,
              closeButton: true,
              closeHtml: "<button>&times;</button>",
            });
            $rootScope.$emit("newPersonalNotification", notification);
          }
        } else {
          switch (vm.notification.type) {
            case 0:
              var statusBar = angular.element(
                document.querySelector("#statusBar")
              );
              statusBar.addClass("update");
              showNotification(notification);
              break;
            case 1:
              var statusBar = angular.element(
                document.querySelector("#statusBar")
              );
              statusBar.addClass("error-conection");
              showNotification(notification);
              break;
            default:
          }
        }
      };
      systemNotificationChannel.subscribe(callback).then(function () {
        vm.sendToMyChannel = function (message) {
          systemNotificationChannel.send(message);
        };
      });
    });
    
  };

  $rootScope.$on("updateProfile", function (ev, data) {
    vm.currentUser = $user.getCurrentUser();
  });

  vm.backToMyUser = function () {
    var user = $window.localStorage.getItem("user_back");
    $window.localStorage.removeItem("user_back");
    $window.localStorage.removeItem("user");
    $user.setCurrentUser(JSON.parse(user));
    $window.location.reload(true);
  };

  vm.reloadCache = function () {
    var newVersion = { version: vm.notification.version };
    var currentVersion = $window.localStorage.system_version;
    if (newVersion != currentVersion) {
      $window.localStorage.removeItem("system_version");
      $timeout(function () {
        $window.localStorage.setItem(
          "system_version",
          angular.toJson(newVersion)
        );
        $timeout(function () {
          $window.location.reload(true);
        }, 1);
      }, 0);
    }
  };

  vm.closeNotification = function () {
    if (vm.notification.type == 2) {
      vm.notification = undefined;
      vm.showMessageNotificationMini = false;
      var element = angular.element(document.querySelector("html"));
      element.removeClass("hasNotificationMini");
    } else {
      vm.notification = undefined;
      vm.showMessageNotification = false;
      var element = angular.element(document.querySelector("html"));
      element.removeClass("hasNotification");
    }
  };

  function showNotification(notification) {
    $timeout(function () {
      var element = angular.element(document.querySelector("html"));
      if (notification.type == 2) {
        element.addClass("hasNotificationMini");
      } else {
        element.addClass("hasNotification");
      }
    }, 1);

    if (notification.type == 2) {
      $timeout(function () {
        vm.showMessageNotificationMini = true;
      }, 200);
    } else {
      $timeout(function () {
        vm.showMessageNotification = true;
      }, 700);
    }
  }

  $rootScope.$on("offline", function (event, data) {
    vm.notification = { type: 1 };
    var statusBar = angular.element(document.querySelector("#statusBar"));
    statusBar.addClass("error-conection");
    showNotification({ type: 1 });
    vm.showMessageNotification = true;
  });
  $rootScope.$on("online", function (event, data) {
    vm.closeNotification();
  });
}
