(function(){
  'use strict';
  angular
    .module('app')
    .component('dashboardAgent', {
      templateUrl: 'app/components/dashboard-agent/dashboard.html',
      controller: DashboardAgentController
    });

    DashboardAgentController.$inject = ['user', 'globalService', '$uibModal', '_','$window','$rootScope','$state'];

  function DashboardAgentController($user, $globalService, $uibModal, _, $window, $rootScope, $state) {
    var vm = this;
    vm.loading = true;
    vm.closeApplication = closeApplication;
    vm.status = undefined;
    
    vm.$onInit = function(){
      vm.currentUser = $user.getCurrentUser();
      vm.currentPage = 1
      vm.per = 20;
      $rootScope.$emit('uploadBreadCrum', {current: 'Dashboard', before: 'Home'});
      $globalService.applicationsDashboard({page: 1, per: vm.per})
      .then(function(res){
        vm.data = res;
        vm.currentPage = vm.data.current_page;
        vm.data.applications = _.map(res.data, function(data){
          // data.urlReport = $globalService.reportPdf('customer_application',{application_id: data.id});
          data.urlReport = $globalService.getUrlPdfGenerator(data.id, vm.currentUser.token);
          //console.log(data.urlReport)
          return data;
        });;
        vm.loading = false;
      })
      $globalService.companies.query(function(res){
        vm.insurances = res;
      })
      $globalService.dashboardCounters()
      .then(function(res){
        vm.counters = res;
      })
    }

    vm.pageChanged = function () {
      vm.send = true;
      vm.currentPage = vm.data.current_page;
      vm.loadingData = true;
      console.log(vm.status, 'vm.status')
      $globalService.applicationsDashboard({page: vm.currentPage, per: vm.per, status: vm.status})
        .then(function (res) {
          vm.send = false;
          vm.data.applications = _.map(res.data, function(data){
            // data.urlReport = $globalService.reportPdf('customer_application',{application_id: data.id});
            data.urlReport = $globalService.getUrlPdfGenerator(data.id, vm.currentUser.token);
            //console.log(data.urlReport)
            return data;
          });
          vm.loadingData = false;
        })
    };

    vm.selectStatus = function(status){
      vm.status = status;
      vm.pageChanged();
    }

    vm.showDocumentsRequired = function(data){
      var modal = $uibModal.open({
        animation: true,
        component: 'requiredDocs',
        windowClass: 'show',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          curreApplication: function(){
            return data;
          }
        }
      });

      modal.result.then(function(res){
        vm.$onInit();
      }, function(err){
        vm.$onInit();
      });
    }

    vm.updateBillInformation = function (application) {
      var modal = $uibModal.open({
        animation: true,
        component: 'modalBIllInformation',
        windowClass: 'show',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          currentApplication: function () {
            return application;
          }
        }
      });

      modal.result.then(function (res) {
        vm.$onInit();
      }, function (err) {
        swal('Error', err.data.message, 'error');
      });
    }


    vm.changeLife = function(data){
      swal({
        title: '¿Esta seguro de hacer el cambio de vida?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function() {
          return new Promise(function(resolve, reject) {
            $globalService.getCustomerApplication(data)
            .then(function(res){
              $state.go('healthApplicationV2',{id: res.id});
              resolve();
            }, function(err) {
              Swal('Stop',err.data.message,'warning');
            });
            
          });
        },
        allowOutsideClick: false,
      }).then(function(data) {});
    }

    function closeApplication(application){
      swal({
        title: 'Write a reason',
        input: 'textarea',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Accept',
        showLoaderOnConfirm: true,
        preConfirm: function (note) {
          if (note) {
            application.unsubscribe_reason = note;
          }
          return $globalService.updateStatusApplication(application)
            .then(function (res) {
              vm.send = false;
              vm.loadingApp = false;
              Swal('Exito', res.message, 'success');
            }, function (err) {
              vm.loadingApp = false;
              Swal('Error', err.data.message, 'warning');
            });
        }
      })
    }

    vm.setIdMember = function(data){
      var modal = $uibModal.open({
        animation: true,
        component: 'memberId',
        windowClass: 'show',
        backdrop: 'static',
        size: 'md',
        resolve: {
          currentApplication: function(){
            return data;
          }
        }
      });

      modal.result.then(function(res){
        vm.$onInit();
      });
    }

    vm.showNote = function(application) {
      vm.loading = true;
      $globalService.resApplications.get({id: application.id, show_note: true}).$promise
      .then(function (res) {
        vm.loading = false;
        var modal = $uibModal.open({
          animation: true,
          component: 'applicationNotes',
          windowClass: 'show',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            currentApplication: function () {
              return res;
            }
          }
        });
      })
    }

    vm.viewNote = function(application) {
      vm.loading = true;
      $globalService.resApplications.get({id: application.id, show_note: true}).$promise
      .then(function (res) {
        vm.loading = false;
        var modal = $uibModal.open({
          animation: true,
          component: 'applicationNotesAgent',
          windowClass: 'show',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            currentApplication: function () {
              return res;
            }
          }
        });
      })
    }

    vm.insuranceCredential = function(application){
      var modal = $uibModal.open({
        animation: true,
        component: 'insuranceCredentials',
        windowClass: 'show',
        backdrop: 'static',
        size: 'md',
        resolve: {
          currentApplication: function () {
            return application;
          }
        }
      });

      modal.result.then(function (res) {
        // vm.$onInit();
      });
    }

    function findApplication(){
      vm.loadingData = true;
      $globalService.findApplication(vm.params)
      .then(function(res){
        
        vm.data.applications = _.map(res.data, function (data) {
          data.single_customer = data.customer.name + ' ' + data.customer.last_name;
          data.single_agent = data.user.full_name;
          data.single_state = data.state.name;
          // data.urlReport = $globalService.reportPdf('customer_application', { application_id: data.id });
          data.urlReport = $globalService.getUrlPdfGenerator(data.id, vm.currentUser.token);
          return data;
        });
        vm.loadingData = false;
        var containDeleteds = _.findWhere(vm.data.applications, {is_deleted: true})
        if(containDeleteds){
          swal('Warning!','Some of the apps may be removed and should not be managed.','warning')
        }
      })
    }

    vm.createNote = function(application) {
      swal({
        title: 'Write a note for later',
        input: 'textarea',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function (note) {
          if (note) {
            application.agent_notes = note;
          }
          return $globalService.saveNoteAgents(application)
          .then(function (res) {
            vm.send = false;
            vm.loadingApp = false;
            Swal('Exito', res.message, 'success');
          }, function (err) {
            vm.loadingApp = false;
            Swal('Error', err.data.message, 'warning');
          });
        }
      })
    }
   

    vm.showUnbsubscribeInfo = function(application) {
      var modal = $uibModal.open({
        animation: true,
        component: 'unsubscribeInfo',
        windowClass: 'show',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          currentApplication: function () {
            return application;
          }
        }
      });
    }
    
    vm.showChangeLife = function (application) {
      var modal = $uibModal.open({
        animation: true,
        component: 'lifechanges',
        windowClass: 'show',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          currentApplication: function () {
            return application;
          }
        }
      });
    }

    vm.createPayment = function(application){
      var modal = $uibModal.open({
        animation: true,
        component: 'paymentsApps',
        windowClass: 'show',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          currentApplication: function () {
            return application;
          }
        }
      });

      modal.result.then(function (res) {
        vm.$onInit();
      });
    }

    vm.goToCreateApplication = function(){
      console.log(vm.currentUser.permits_granted.health_form_v2.active)
      if(vm.currentUser.permits_granted.health_form_v2.active){
        $state.go('healthApplicationV2',{id: null});
      }else{
        $state.go('applications',{id: null});
      }
    }

    vm.viewCustomerSms = function(application){
      $state.go('customerSms',{crmid: application.id});
    }

    vm.processApplication = function(application){
      var modal = $uibModal.open({
        animation: true,
        component: 'proccessLink',
        windowClass: 'show',
        backdrop: 'static',
        size: 'xs',
        resolve: {
          currentApplication: function(){
            return application;
          }
        }
      });

      modal.result.then(function(res){
        vm.$onInit();
      }, function(err){
        vm.$onInit();
      });
    }

    $rootScope.$on('searchApp', function(evt,data){
      //console.log('asdsad')
      vm.params = data.params;
      findApplication()
    })


  }


})();
