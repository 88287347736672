(function () {
  'use strict';
  angular
    .module('app')
    .component('manageApplications', {
      templateUrl: 'app/components/manage-applications/manage-applications.html',
      controller: ManageApplicationsController
    });

  ManageApplicationsController.$inject = ['user', '$rootScope', '$interval', '_', 'globalService', '$uibModal', 'url', '$window', '$state'];

  function ManageApplicationsController($user, $rootScope, $interval, _, $globalService, $uibModal, url, $window, $state) {
    var vm = this;
    vm.loadingRequests = false;
    vm.currentUser = $user.getCurrentUser();
    vm.showCurrentApplication = false;
    vm.loadingData = false;
    vm.changeAgent = changeAgent;
    vm.requireDocuments = [];
    vm.closeApplication = closeApplication;
    vm.listRequireDocuments = ['Income', 'Social Security Number', 'Status Migratory', 'Relocation', 'Lost Cover', 'Others'];
    vm.tabPanel = {
      personalData: true,
      insuranceData: false,
      billingInformation: false,
      requiredData: false,
      availabilityData: false,
      memberIdsInfo: false,
      documents: false
    }
    vm.index = {
      firstTab: 0,
      secondTab: 1,
      thirdTab: 2,
      fourthTab: 3,
      fiveTab: 4,
      insuranceTab: 5,
      documentsTab: 6
    }

    vm.$onInit = function () {
      $rootScope.$emit('uploadBreadCrum', { current: 'Applications', before: 'Home' });
      vm.loadingData = true;
      vm.per = 20;
      vm.currentPage = 1;


      $globalService.applicationsDashboard({ page: 1, per: vm.per, location: 'dashboard_admins' })
        .then(function (res) {
          vm.data = res;
          vm.currentPage = vm.data.current_page;
          vm.send = false;
          vm.data.applications = _.map(res.data, function (data) {
            data.urlReport = $globalService.reportPdf('customer_application', { application_id: data.id }) + '&token='+vm.currentUser.token;
            data.newUrlReport = $globalService.getUrlPdfGenerator(data.id, vm.currentUser.token);
            return data;
          });
          vm.loadingData = false;
          $globalService.type_insurance_companies()
          .then(function (res) {
            vm.type_insurance_companies = res;
          })
          $globalService.companies.query(function (res) {
            vm.insurances = res;
          })

        })

    }

    vm.pageChanged = function () {
      vm.send = true;
      vm.loadingData = true;
      vm.currentPage = vm.data.current_page;
      $globalService.applicationsDashboard({ page: vm.currentPage, per: vm.per, location: 'dashboard_admins' })
        .then(function (res) {
          vm.send = false;
          vm.data.applications = _.map(res.data, function (data) {
            data.urlReport = $globalService.reportPdf('customer_application', { application_id: data.id }) + '&token='+vm.currentUser.token;
            data.newUrlReport = $globalService.getUrlPdfGenerator(data.id, vm.currentUser.token);
            return data;
          });
          vm.loadingData = false;
        })
    };

    vm.process = function (data) {
      console.log(data,'data')
      if(data.status == 1){
        findResApplications(data.id, null)
      }else{
        var modal = $uibModal.open({
          animation: true,
          component: 'modalProcess',
          windowClass: 'show',
          backdrop: 'static',
          size: 'md',
          resolve: {
            crmid: function () {
              return data.id;
            },
            currentApplication: function () {
              return data;
            }
          }
        });
  
        modal.result.then(function (res) {
          findResApplications(data.id, res.user_npn, res.form_type)
        }, function (err) {
          swal('Error', err.data.message, 'error');
        });
      }
      
    }

    function findResApplications(crmid, user_npn, form_type){
      vm.form_type = form_type;
      var params = {}
      params['id'] = crmid;
      if(user_npn != null){
        params['user_npn'] = user_npn;
        params['form_type'] = form_type;
      }
      $globalService.resApplications.get(params).$promise
      .then(function (res) {
        vm.loadingData = false;
        vm.currentApplication = res;
        vm.showCurrentApplication = true;
        vm.allCompanies = getAllCompanies();

        vm.companySelected = _.find(vm.allCompanies, { id: vm.currentApplication.insurance_data.company.id });
        vm.productSelected = _.find(vm.companySelected.products, { id: vm.currentApplication.insurance_data.company_product.id });

        vm.typePlanSelected = _.find(vm.productSelected.company_product_type, { id: vm.currentApplication.insurance_data.company_product_type.id });
        vm.planSelected = _.find(vm.typePlanSelected.company_product_plans, { id: vm.currentApplication.insurance_data.company_product_plan.id });

        $rootScope.$emit('setCurrentApplicant', {
          current: vm.currentApplication,
          insurances: vm.insurances,
          allCompanies: vm.allCompanies,
          companySelected: vm.companySelected,
          productSelected: vm.productSelected,
          typePlanSelected: vm.typePlanSelected,
          planSelected: vm.planSelected,
          applicationTypeSelected: vm.applicationTypeSelected
        });
        if (vm.currentUser.permits_granted.change_agent.active) {
          geAllAgent();
        }
      }, function (err) {
        vm.loadingData = false;
        vm.showCurrentApplication = false;
        Swal('Error', err.data.message, 'error');
      })
    }

    vm.changeAgents = function (application) {
      var modal = $uibModal.open({
        animation: true,
        component: 'changeAgent',
        windowClass: 'show',
        backdrop: 'static',
        size: 'md',
        resolve: {
          currentApplication: function () {
            return application;
          }
        }
      });

      modal.result.then(function (res) {
        vm.$onInit();
      }, function (err) {
        swal('Error', err.data.message, 'error');
      });
    }

    vm.changeNpn = function (application) {
      var modal = $uibModal.open({
        animation: true,
        component: 'changeNpn',
        windowClass: 'show',
        backdrop: 'static',
        size: 'md',
        resolve: {
          currentApplication: function () {
            return application;
          }
        }
      });

      modal.result.then(function (res) {
        vm.$onInit();
      }, function (err) {
        swal('Error', err.data.message, 'error');
      });
    }

    vm.updateBillInformation = function (application) {
      var modal = $uibModal.open({
        animation: true,
        component: 'modalBIllInformation',
        windowClass: 'show',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          currentApplication: function () {
            return application;
          }
        }
      });

      modal.result.then(function (res) {
        vm.$onInit();
      }, function (err) {
        swal('Error', err.data.message, 'error');
      });
    }

    vm.setActiveTab = function (tab) {
      _.forEach(Object.keys(vm.tabPanel), function (key) {
        if (vm.tabPanel[key]) {
          vm.tabPanel[key] = false;
        }
      })
      vm.tabPanel[tab] = true;
    }

    vm.addRequireDocuments = function () {
      if (moment(vm.dateTo) < moment()) {
        Swal('Stop', 'The date must be greater than the current date or it must not be empty', 'info');
        return
      } else {
        vm.currentApplication.required_documents = vm.currentApplication.required_documents || [];
        vm.currentApplication.required_documents.push({
          required_document: vm.requiredDocument == 'Others' ? vm.otherRequiredDocument : vm.requiredDocument,
          who_require_it: vm.whoRequires,
          date_to: moment(vm.dateTo).format('MM/DD/YYYY'),
          status: 0,
          pretty_status: 'Pendiente',
        })
        vm.requiredDocument = undefined;
        vm.whoRequires = undefined;
        vm.dateTo = undefined;
      }
    }

    vm.sendDocuments = function () {
      if (vm.currentApplication.required_documents.length == 0) {
        Swal('Stop', 'You must add at least one document', 'info');
        return
      } else {
        vm.loadingData = true;
        var data = {
          customer_application_id: vm.currentApplication.id,
          documents: _.reduce(vm.currentApplication.required_documents, function (memo, data) {
            if (data.id == undefined) {
              memo.push(data);
            }
            return memo;
          }, [])
        }

        swal({
          title: '¿Esta seguro?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Accept',
          showLoaderOnConfirm: true,
          preConfirm: function () {
            return new Promise(function (resolve, reject) {
              $globalService.requiredDocuments(data)
                .then(function (res) {
                  swal('Exito', res.message, 'success');
                  vm.loadingData = false;
                  vm.process(vm.currentApplication);
                }, function (err) {
                  swal('Error', res.data.message, 'danger');
                  vm.loadingData = false;
                })
            });
          },
          allowOutsideClick: false,
        }).then(function (data) { });
      }
    }


    vm.updateRequiredDoc = function (doc) {
      swal({
        title: '¿Esta seguro?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Accept',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.updateDocument(doc)
              .then(function (res) {
                doc.status = 1;
                doc.pretty_status = 'Enviado';
                swal('Exito', res.message, 'success');
              }, function () {
                reject('Ha ocurrido un error');
              });
          });
        },
        allowOutsideClick: false,
      }).then(function (data) { });
    }

    vm.deleteRequiredDoc = function (doc, index) {
      swal({
        title: '¿Esta seguro?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Accept',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.deleteDocument(doc)
              .then(function (res) {
                vm.currentApplication.required_documents.splice(index, 1)
                swal('Exito', res.message, 'success');
              }, function () {
                reject('Ha ocurrido un error');
              });
          });
        },
        allowOutsideClick: false,
      }).then(function (data) { });
    }

    vm.resolve_conflict = function (data) {
      swal({
        title: '¿Esta seguro?',
        text: 'Al resolver el conflicto la aplicacion sera asignada al agente que envio y se mostrara en el panel de managers',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Accept',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.resolvConflict(data)
              .then(function (res) {
                swal('Exito', res.message, 'success');
              }, function () {
                reject('Ha ocurrido un error');
              });
          });
        },
        allowOutsideClick: false,
      }).then(function (data) {
        vm.$onInit();
      });

    }



    vm.finishApplication = function () {
      var modal = $uibModal.open({
        animation: true,
        component: 'processApplication',
        windowClass: 'show',
        backdrop: 'static',
        size: 'md',
        resolve: {
          currentApplication: function () {
            return vm.currentApplication;
          }
        }
      });

      modal.result.then(function (res) {
        vm.$onInit();
        vm.showCurrentApplication = false;
        vm.currentApplication = undefined;
      }, function (err) {
        swal('Error', err.data.message, 'error');
      });
    }

    vm.conflics = function (data) {
      if (data.status == 3) {
        Swal('Info', 'Aplicacion ya tiene el conflicto identificado', 'info');
        return;
      }
      var modal = $uibModal.open({
        animation: true,
        component: 'conflictAgents',
        windowClass: 'show',
        backdrop: 'static',
        size: 'md',
        resolve: {
          currentApplication: function () {
            return data;
          },
          agents: function () {
            return vm.agents;
          }
        }
      });

      modal.result.then(function (res) {
        vm.$onInit();
      }, function (err) {
        swal('Error', err.data.message, 'error');
      });
    }

    vm.setIdMember = function (data) {
      var modal = $uibModal.open({
        animation: true,
        component: 'memberId',
        windowClass: 'show',
        backdrop: 'static',
        size: 'md',
        resolve: {
          currentApplication: function () {
            return data;
          }
        }
      });

      modal.result.then(function (res) {
        vm.$onInit();
      });
    }

    vm.updateApplication = function (e) {
      switch (e) {
        case 0:
          // updateMainApplicant();
          $rootScope.$emit('updateMainApplicant');
          break;
        case 1:
          // updateSpouseApplicant();
          $rootScope.$emit('updateSpouseApplicant');
          break;
        case 2:
          $rootScope.$emit('updateDependentApplicants');
          // updateDependentApplicant();
          break;
        case 3:
          $rootScope.$emit('updateBillInformation');
          // updateBillInformation();
          break;
        case 5:
          //console.log('5')
          updateInsuranceData();
          break;
        default:
          break;
      }
    }

    function closeApplication(application) {
      var modal = $uibModal.open({
        animation: true,
        component: 'bajaReason',
        windowClass: 'show',
        backdrop: 'static',
        size: 'md'
      });

      modal.result.then(function (reason) {
        application.unsubscribe_reason = reason;
        swal({
          title: 'You are sure to set reason:' + reason + '?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Accept',
          showLoaderOnConfirm: true,
          preConfirm: function () {
            return new Promise(function (resolve, reject) {
              $globalService.updateStatusApplication(application)
              .then(function (res) {
                vm.send = false;
                vm.loadingApp = false;
                Swal('Exito', res.message, 'success');
              }, function (err) {
                vm.loadingApp = false;
                Swal('Error', err.data.message, 'warning');
              });

            });
          },
          allowOutsideClick: false,
        }).then(function (data) { });
      });
    }

    function getAllCompanies() {
      vm.allCompanies = _.reduce(vm.type_insurance_companies, function (memo, data) {
        _.forEach(data.companies, function (company) {
          memo.push(company)
        })
        return memo;
      }, [])

      return vm.allCompanies;
    }

    function updateInsuranceData() {
      $rootScope.$emit('updateInsuranceData')
    }

    vm.showNote = function (application) {
      $globalService.resApplications.get({id: application.id, show_note: true}).$promise
      .then(function (res) {
        vm.loadingRequests = false;
        var modal = $uibModal.open({
          animation: true,
          component: 'applicationNotes',
          windowClass: 'show',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            currentApplication: function () {
              return res;
            }
          }
        });
      })
    }

    vm.showUnbsubscribeInfo = function(application) {
      var modal = $uibModal.open({
        animation: true,
        component: 'unsubscribeInfo',
        windowClass: 'show',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          currentApplication: function () {
            return application;
          }
        }
      });
    }
    
    vm.showChangeLife = function (application) {
      vm.loadingRequests = true;
      $globalService.resApplications.get({id: application.id}).$promise
      .then(function (res) {
        vm.loadingRequests = false;
        var modal = $uibModal.open({
          animation: true,
          component: 'lifechanges',
          windowClass: 'show',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            currentApplication: function () {
              return res;
            }
          }
        });
      })
    }

    vm.sendNoteApplication = function (application) {
      swal({
        title: 'Write the note to the agent',
        input: 'textarea',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Accept',
        showLoaderOnConfirm: true,
        preConfirm: function (note) {
          if (note) {
            application.note = note;
          }
          return $globalService.updateCustomerApplication(application)
            .then(function (res) {
              vm.send = false;
              vm.loadingApp = false;
              Swal('Exito', res.message, 'success');
            }, function (err) {
              vm.loadingApp = false;
              Swal('Error', err.data.message, 'warning');
            });
        }
      })
    }

    vm.deleteApplication = function(app){
      vm.send = true;
      vm.code = _.random(9999);
      swal({
        title: 'Enter this code '+vm.code+' to confirm delete.',
        input: 'number',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        showLoaderOnConfirm: true,
        preConfirm: function(code) {
          if(code == vm.code){
            return $globalService.resApplications.delete({id: app.id}).$promise
            .then(function(res){
              vm.send = false;
              vm.loadingApp = false;
              Swal('Exito',res.message,'success');
            }, function(err){
              vm.loadingApp = false;
              swal.showValidationError(err.data.message);
            });
          }
        }
      })
    }

    // column to sort
    vm.column = 'sno';

    // sort ordering (Ascending or Descending). Set true for desending
    vm.reverse = false;

    // called on header click
    vm.sortColumn = function (col) {
      vm.column = col;
      if (vm.reverse) {
        vm.reverse = false;
        vm.reverseclass = 'arrow-up';
      } else {
        vm.reverse = true;
        vm.reverseclass = 'arrow-down';
      }
    };

    // remove and change class
    vm.sortClass = function (col) {
      if (vm.column == col) {
        if (vm.reverse) {
          return 'fa-sort-amount-asc';
        } else {
          return 'fa-sort-amount-desc';
        }
      } else {
        return 'fa-sort';
      }
    }

    vm.changeLife = function (data) {
      swal({
        title: 'You are sure to make the change of life?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Accept',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.getCustomerApplication(data)
              .then(function (res) {
                $state.go('healthApplicationV2',{id: res.id});
                resolve();
              }, function (err) {
                Swal('Stop', err.data.message, 'warning');
              });

          });
        },
        allowOutsideClick: false,
      }).then(function (data) { });


    }

    function findApplication() {
      vm.loadingData = true;
      $globalService.findApplication(vm.params)
        .then(function (res) {

          vm.data.applications = _.map(res.data, function (data) {
            data.single_agent = data.user.full_name;
            data.single_state = data.state.name;
            data.urlReport = $globalService.reportPdf('customer_application', { application_id: data.id }) + '&token='+vm.currentUser.token;
            data.newUrlReport = $globalService.getUrlPdfGenerator(data.id, vm.currentUser.token);
            return data;
          });
          vm.loadingData = false;
          var containDeleteds = _.findWhere(vm.data.applications, {is_deleted: true})
          if(containDeleteds){
            swal('Warning!','Some of the apps may be removed and should not be managed.','warning')
          }

          


        })
    }

    function geAllAgent() {
      $globalService.getAllAgents.query(function (res) {
        vm.agents = res;
        vm.agentSelected = _.find(res, { id: vm.currentApplication.user.id });
      }).promise
    }

    function changeAgent() {
      vm.updateApplication(vm.index.firstTab)
    }

    vm.createNote = function (application) {
      swal({
        title: 'Write a note for later',
        input: 'textarea',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Accept',
        showLoaderOnConfirm: true,
        preConfirm: function (note) {
          if (note) {
            application.agent_notes = note;
          }
          return $globalService.saveNoteOnApplication(application)
            .then(function (res) {
              vm.send = false;
              vm.loadingApp = false;
              Swal('Exito', res.message, 'success');
            }, function (err) {
              vm.loadingApp = false;
              Swal('Error', err.data.message, 'warning');
            });
        }
      })
    }

    vm.createPayment = function(application){
      var modal = $uibModal.open({
        animation: true,
        component: 'paymentsApps',
        windowClass: 'show',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          currentApplication: function () {
            return application;
          }
        }
      });

      modal.result.then(function (res) {
        // vm.$onInit();
      });
    }

    vm.insuranceCredential = function(application){
      var modal = $uibModal.open({
        animation: true,
        component: 'insuranceCredentials',
        windowClass: 'show',
        backdrop: 'static',
        size: 'md',
        resolve: {
          currentApplication: function () {
            return application;
          }
        }
      });

      modal.result.then(function (res) {
        // vm.$onInit();
      });
    }

    vm.migrateMembers = function(application){
      var modal = $uibModal.open({
        animation: true,
        component: 'MembersMigration',
        windowClass: 'show',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          currentApplication: function () {
            return application;
          }
        }
      });

      modal.result.then(function (res) {
        // vm.$onInit();
      });
    }

    vm.processApplication = function(application){
      var modal = $uibModal.open({
        animation: true,
        component: 'proccessLink',
        windowClass: 'show',
        backdrop: 'static',
        size: 'xs',
        resolve: {
          currentApplication: function(){
            return application;
          }
        }
      });

      modal.result.then(function(res){
        vm.$onInit();
      }, function(err){
        vm.$onInit();
      });
    }

    vm.viewCustomerSms = function(application){
      $state.go('customerSms',{crmid: application.id});
    }

    vm.viewNote = function (application) {
      Swal('Note', application.agent_notes, 'info');
    }

    vm.sendSyncNotify = function(application){
      var data = {
        user_id: application.user_id
      }
      swal({
        title: 'Are you sure to send sync notification? to: '+application.user+'?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function() {
          return new Promise(function(resolve, reject) {
            $globalService.sendNotifySync(data)
            .then(function(res){
              resolve(res);
            }, function(err) {
              Swal('Error',err.data.message,'warning');
            });
          });
        },
        allowOutsideClick: false,
      }).then(function(res) {
        if(res.value){
          swal('Exito',res.value.message,'success');
        }
      });
    }

    $rootScope.$on('searchApp', function (evt, data) {
      vm.params = data.params;
      findApplication()
    })

    $rootScope.$on('initAllAplications', function (e, d) {
      vm.$onInit();
    })
  }
})();
